import { gql, loader } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
const dsGetLabReports = loader('./dsGetLabReports.graphql');

const dsGetLabReportsFull = gql`query dsGetLabReports($ref: String, $limit: Int, $jfilt: [JSON], $offset: Int, 
    $sort: sort,
    $requireTotalCount: Boolean!
  ) {
    totalcount: lab_report(
      ref: $ref, jfilt: $jfilt, totalCount: 1
    ) @include(if: $requireTotalCount) {
      totalcount
    }
  
    list: lab_report(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, 
      sort: $sort
    ) {
      ref
      amount
      rp rv
      status
      number_doc
      date
      has_error
      vehicle_map
      note
      partner { ref name individual_legal }
      service { ref name }
      blank_series
      blank_number
      warn_maybe_error
      invoice { ref caption number_doc doc_amount pay_kind date isSubContract ext_json
       services { nomRef gos_code quantity amount msto }
       badact_reason
      }  
      vehicle_model {ref id name type {ref name} brand {ref name} }
      source_report {ref}
      gnumber
      ext_json
    }
  }`;



export const dsLabReports = createDataSource(dsGetLabReports);
export const dsLabReportsFull = createDataSource(dsGetLabReportsFull);
