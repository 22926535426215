import { useRef } from 'react';
import { DoclistPageTemplate } from 'components';
import { Menu } from 'devextreme-react';
import { useNavigate } from 'react-router';
import { dsEasyPayMessages } from 'datasources';
import { useDocChangeListener } from 'hooks';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { statuses, types_message } from './easypay_const';
import { ItemInfo, MenuItem } from 'types/otkTypes';

export const EasyPayList = () => {
  const gridRef = useRef<DataGrid>(null);
  const navigate = useNavigate();

  useDocChangeListener('doc.easypay_message', () => {
    gridRef.current?.instance.refresh(true);
  });

  return (
    <DoclistPageTemplate
      gridRef={gridRef}
      columnsDataFields = {{
        date: 'date',
        docNumber: 'number_doc',
      }}
      pageName='Повідомлення про інциденти EasyPay'
      dataSource={dsEasyPayMessages}
      onEdit={(ref) => navigate(`/easypaymessage/${ref}`)}
      menuComponent={
        <Menu
          onItemClick={({itemData}:ItemInfo) => {
            switch (itemData?.id) {
            case 'new': {
              navigate('/easypaymessage/new');
              break;
            }
          }}}
          dataSource={[
            {
              text: 'Додати',
              icon: 'add',
              id: 'new',
            },
          ] as MenuItem[]}
        />
      }
    >
      <Column
        dataField='typeof_message'
        caption='Тип інциденту'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      >
        <Lookup dataSource={types_message} valueExpr="code"  displayExpr="caption"/>
      </Column>
      <Column
        dataField = 'status'
        caption='Статус'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      >
        <Lookup dataSource={statuses} valueExpr="code" displayExpr="caption"/>
      </Column>
      <Column
        dataField='description'
        caption='Опис'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      />
      <Column
        dataField='note'
        caption='Коментар'
        dataType='string'
        alignment='center'
        allowHiding={false}
        allowResizing={true}
      />
    </DoclistPageTemplate>
  );
};
