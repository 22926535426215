import { DOC_TEMPLATES_HR_FOLDER_URL, roles } from './app-constants';

export const navigation = [
  {
    text: 'Головна',
    path: '/dashboard',
    icon: 'home',
  },
  {
    text: 'Ціни',
    path: '/pricelist',
    icon: 'favorites',
  },
  {
    text: 'Замовлення',
    path: '/orders',
    icon: 'car',
    notification: 'newOrdersCount',
  },
  {
    text: 'Акти',
    path: '/acts',
    icon: 'check',
  },
  {
    text: 'Електронні протоколи',
    icon: 'box',
    path: '/ep',
  },
  {
    text: 'Протоколи ОТК',
    path: '/lab_reports',
    icon: 'verticalalignbottom',
    notification: 'reportsWithErrorCount',
  },
  {
    text: 'Сервіси',
    icon: 'folder',
    items: [
      {
        text: 'Замовлення бланкiв',
        path: '/form_orders',
      },
      {
        text: 'Замовлення знижки',
        path: '/price_orders',
      },
      {
        text: 'Табель',
        path: '/work_times',
      },
      {
        text: 'Продаж СП',
        path: '/policy',
      },
    ],
  },
  {
    text: 'Звіти',
    icon: 'print',
    items: [
      {
        text: 'Рух грошових коштів',
        icon: 'money',
        path: '/cash_flow_report',
      },
      {
        text: 'Заборгованість за виконаними роботами',
        icon: 'money',
        path: '/debitsbyact_report',
      },
      {
        text: 'Оплати термінал та он-лайн',
        icon: 'money',
        path: '/pay_report',
      },
    ],
  },

 {
    text: 'Інформація',
    icon: 'info',
    items: [
      {
        text: 'Довідка',
        icon: 'tips',
        path: '/manuals-moc',
      },
      {
        text: 'Шаблони документів',
        icon: 'doc',
        items: [
          {
            text: 'Відділ кадрів',
            path: DOC_TEMPLATES_HR_FOLDER_URL,
            isLink: true,
          },
        ],
      },
      {
        text: 'Договір оферти',
        icon: 'doc',
        path: '/oferta',
      },
      {
        text: 'Новини',
        path: '/news',
        icon: 'news',
      },
    ]
  },  
  {
    text: 'Адмінка',
    icon: 'preferences',
    items: [
      {
        text: 'Налаштування',
        icon: 'preferences',
        path: '/adminka',
        roles: [roles.ADMIN],
      },
      {
        text: 'Звіти (адмін)',
        icon: 'ion ion-md-print',
        path: '/adminka/reports',
        roles: [roles.ADMIN],
      },
      {
        text: 'Імпорт даних авто',
        icon: 'import',
        path: '/import-cars',
        roles: [roles.ADMIN],
      },
    ],
    roles: [roles.ADMIN],
  },
];
