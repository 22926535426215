
import CustomStore, { Options } from "devextreme/data/custom_store";

export class CustomStoreExt<TItem=any, TKey=any> extends CustomStore<TItem, TKey> {
    private _userData?: any
    userOptions: {
      [key: string]: any
    };
  
    constructor(options?:Options<TItem, TKey>) {
      super(options);
      this.userOptions = {}
    }

    set userData (userData:any) {
        this._userData = userData;
    }
    get userData ():any {
        return this._userData;
    }
  }