import { loader } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
const dsGetNoms = loader('./dsGetNoms.gql');

// use default proj ref as OTK
const otkProj = 'afe61914-f69c-11eb-8ac0-00155d000c0a';

export const dsNoms = createDataSource(dsGetNoms);

export const dsProjNoms = (projRef, lab, doc_type = null) => {
  const services = lab?.services?.map((row) => row.nom) ?? [];
  const dsProxy = Object.create(dsNoms);
  const projFilter = [ 'proj', '=', projRef ?? otkProj ];
  dsProxy.load = (options) => dsNoms.load({ ...options, filter: projFilter })
    .then((rows) => {
      if (services?.length) {
        const filtered = rows?.data?.filter(
          (row) => services.includes(row.ref));
        if (filtered.length) rows.data = filtered;
      }
      //filtering by doc_type
      rows.data = rows?.data?.filter((row) => row?.doc_type === doc_type);
      return rows;
    });
  return dsProxy;
};
