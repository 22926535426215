export const labels = {
    dateRange: { text: 'Виберіть діапазон дат' },
    mailcc: 'Копія на email:',
    tableHeads: {
      vehicle_map: 'Відповідність',
      number_doc: '№ документа',
      blank: 'Бланк',
      status: 'Статус',
      date: 'Дата',
      time: 'Час',
      vehicle_model: {
        name: 'Модель',
        type: {
          name: 'Тип ТЗ',
        },
        brand: {
          name: 'Марка',
        },
      },
      service: {
        name: 'Категорія',
      },
      gnumber: 'Держ.номер',
      partner_name: 'Замовник',
      individual_legal: 'Тип власника',
      pay_kind: 'Тип Опл',
      amount: 'Сума',
      rp: 'Термінал',
      rv: 'РВ',
      order: 'Замовлення',
    },
  }; 

  export const groupBy = (list:any[], keyGetter:(a:any)=>string) => {
    const map = new Map();
    list?.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };