import { useRef } from 'react';
import DataGrid, { Column, Lookup } from 'devextreme-react/data-grid';
import { useNavigate, useLocation } from 'react-router-dom';
import { COLUMN_PRICE_WIDTH, diiaStatuses } from 'app-constants';
import { mapDiiaStatusesToTypes } from './constants';
import { useDocChangeListener, useFirstLoginedView, useMessageVars } from 'hooks';
import { DoclistPageTemplate, statusCellRender } from 'components';
import { dsLabReports } from 'datasources';
import { Menu } from 'devextreme-react';
import { ItemInfo, MenuItem } from 'types/otkTypes';

export const LabReports = () => {
  const navigate = useNavigate();
  const gridRef = useRef<DataGrid>(null);
  const location = useLocation();

  useDocChangeListener('doc.lab_report', () => gridRef.current?.instance.refresh(true));

  const [ numErrors ] = useMessageVars([ 'reportsWithErrorCount' ]);
  const isFirstView = useFirstLoginedView(location.pathname);
  const showErrors = isFirstView && numErrors;

  return (
    <DoclistPageTemplate
      gridRef={gridRef}
      pageName='Протоколи ОТК'
      onEdit={(ref) => navigate(`/lab_report/${ref}`)}
      dataSource={dsLabReports}
      stateStoringEnabled={!showErrors}
      onRowPrepared={(e) => {
        if (e.data?.warn_maybe_error && e.rowType === 'data') {
          e.rowElement.classList.add('otk-row-status-warning');
        }
        if (e.data?.has_error && e.rowType === 'data') {
          e.rowElement.classList.add('otk-row-status-error');
        }
      }}
      headerBarComponent={numErrors ?
        <div className={'otk-tag otk-status-error'}>
          <div className='dx-icon-warning'/>
          &nbsp;Звіти з помилками: {numErrors}
        </div> : null
      }
      menuComponent={(
        <Menu
          onItemClick={({itemData}:ItemInfo) => {
            switch (itemData?.id) {
            case 'reports': {
              if (itemData.url) navigate(itemData.url);
              break;
            }
            default:
            }
          }}
          dataSource={[
            {
              text: 'Звіти',
              icon: 'verticalalignbottom',
              id: 'reports',
              items: [
                {
                  id: 'reports',
                  text: 'Звіт за період',
                  url: '/daylabreport',
                },
              ],
            },
          ] as MenuItem[]}
        />
      )}
    >
      <Column
        cssClass='otk-cell-no-padding'
        allowSorting={true}
        dataField='has_error'
        caption='Є помилки'
        dataType='boolean'
        cellComponent={(e) => {
          if (e.data.data?.warn_maybe_error || e.data.value) {
            const warnError = e.data.value ?
              'dx-icon-warning otk-error' : 'dx-icon-info otk-warning';
            return <div className={`${warnError} otk-font-20`} />;
          }
          return null;
        }}
        filterValue={showErrors ? true : null}
        alignment='center'
        width={60}
      />

      <Column
        allowSorting={true}
        dataField='service.name'
        caption='Послуга'
        dataType='string'
        alignment='center'
        hidingPriority={3}
        width={80}
      />

      <Column
        allowSorting={true}
        dataField='gnumber'
        caption='Держ.номер'
        dataType='string'
        alignment='center'
        width={80}
      />
      <Column
        visible={false}
        cssClass='otk-cell-no-padding'
        dataField='status'
        dataType='string'
        caption='Статус Дія'
        alignment='center'
        width={120}
        cellRender={(data) => statusCellRender(data, mapDiiaStatusesToTypes)}
      >
        <Lookup
          dataSource={Object.values(diiaStatuses)}
          valueExpr='status'
          displayExpr='statusText'
          allowClearing={true}
        />
      </Column>

      <Column
        allowSorting={true}
        dataField='amount'
        caption='Сума'
        dataType='number'
        alignment='right'
        width={COLUMN_PRICE_WIDTH}
        hidingPriority={1}
      />
    </DoclistPageTemplate>
  );
};

