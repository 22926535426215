import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Popover } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid, {
  Column,
  Editing,
  Format,
  LoadPanel,
  Paging,
  Sorting,
  StateStoring,
  Summary,
  Texts,
  TotalItem,
} from 'devextreme-react/data-grid';
import { dsProjNoms } from 'datasources';
import { AutocompleteOTK } from 'components/otk/AutocompleteOTK';
import { DocContext } from 'contexts/docContext';
import { NatsPopup } from '../nats-popup';
import { CustomRule } from 'devextreme-react/form';
import { normalizeCarNumber } from 'utils/normalize-car-number';
import EpSimpleBtn from 'components/otk/epSimpleBtn';
import { selectOnEdit, getMstoValue } from 'utils/helpers';
import './sgrid.scss';
import { showError } from 'utils/notify';
import { useAuth } from 'contexts';

const popoverAnimationConfig = {
  show: {
    type: 'pop',
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
};

const columnChooserOptions = {
  enabled: false,
  mode: 'select',
  height: 350,
};

export const ServiceGrid = () => {
  const { projects } = useAuth();
  const {
    state: { doc, visibles },
  } = useContext(DocContext);
  const { _isReadOnly: docReadOnly, services: dataSource, useAddPrice, proj, _lab: lab, _options: options } = doc;

  const [grid, setGrid] = useState();
  const [percentUpVisible, setPercentUpVisible] = useState(false);

  const servicesDataGridRef = useRef();
  const selRowData = useRef();
  const mstoValue = getMstoValue(doc.date, options);

  useEffect(() => setGrid(servicesDataGridRef.current?.instance), []);

  const isBadAct = doc?.ext_json?.doc_type === 'АктНесоответствия';

  const addButtonOptions = {
    icon: 'plus',
    onClick: () => {
      if (isBadAct) {
        showError('Даний тип документу передбачає лише 1 послугу');
        return;
      }
      dataSource.push({
        ...doc.emptyServiceLine(),
        row: grid.totalCount() + 1,
      });
      grid.refresh(true);
    },
    hint: 'Додати новий рядок',
  };

  useEffect(() => {
    if (grid && doc) {
      grid.columnOption('msto', 'visible', visibles.msto?.visible);
      grid.columnOption('article', 'visible', visibles.article?.visible);
      grid.columnOption('ep', 'visible', visibles.ep?.visible);
      grid.columnOption('addPerc', 'visible', useAddPrice);
    }
  }, [grid, proj, doc, visibles, useAddPrice]);

  const copyButtonOptions = {
    icon: 'copy',
    onClick: () => {
      dataSource.push({
        ...selRowData.current,
        row: grid.totalCount() + 1,
      });
      grid.refresh(true);
    },
    hint: 'Копіювати виділений рядок',
  };

  const addPercentButtonOptions = {
    icon: 'percent',
    text: 'націнка',
    onClick: () => setPercentUpVisible(true),
    hint: 'Встановити націнку процентом',
  };

  const columnsButtonOptions = {
    icon: 'columnchooser',
    onClick: () => grid.showColumnChooser(),
    hint: 'Видимість колонок',
  };

  const handleRowUpdating = (e) => {
    const { newData, oldData } = e;
    if ('msto' in newData) {
      newData.nats = oldData.nats - mstoValue;
      if (newData.msto) newData.nats = oldData.nats + mstoValue;
    }
    if ('gos_code' in newData) {
      newData.gos_code = normalizeCarNumber(newData.gos_code);
    }
    e.newData = doc.recalcRowService({ ...oldData, ...newData });
  };

  const percentColumn = useCallback(
    (rowData) => {
      if (!rowData.nom) return 0;
      const basePrice = doc.getBasePrice(rowData.nom.ref);
      const rowPrice = rowData.price;
      const mstoVal = rowData.msto ? mstoValue : 0;
      const discPerc = rowData.discount_percent_automatic ?? 0;
      const discValue = (rowPrice * discPerc) / 100;
      const calcPrice = rowPrice - discValue + rowData.nats - mstoVal;
      return basePrice ? (calcPrice / basePrice - 1) * 100 : 0;
    },
    [doc, mstoValue]
  );

  const percTemplate = () => (
    <div id='headerPrc' style={{ textAlign: 'center' }}>
      % от <i className='dx-icon-info'></i>
      <br />
      (прайс)
      <Popover
        target='#headerPrc'
        showEvent={{ delay: 500, name: 'mouseenter' }}
        hideEvent='mouseleave'
        position={{ my: 'bottom center', at: 'top center', collision: 'fit flip' }}
        delay={1000}
        width={250}
        title='Details'
        animation={popoverAnimationConfig}
        wrapperAttr={{ class: 'otk-popover' }}
      >
        <span>
          Колонка розраховується як відсоток відхилення ціни від <strong>&quot;базового&quot;</strong> прайсу лабораторії
        </span>
      </Popover>
    </div>
  );

  const epSimpleBtnTemplate = (r) => {
    if (doc.isNew) return <></>
    return <EpSimpleBtn _id={doc.ref} _key={r.data?.row?.key - 1} type_EP_ref={proj} item={r.data?.row?.data.nom} />
  }
  ;

  const changeNats = (natsPercent, asDiscont = false) => {
    dataSource.forEach((r) => {
      const msto = r.msto ? mstoValue : 0;
      if (asDiscont) {
        r.discount_percent_automatic = natsPercent * 100;
        r.nats = 0;
        const discont = r.price * natsPercent;
        const basePrice = doc.getBasePrice(r.nom.ref);
        const maxDisdcont = r.price - basePrice;
        if (discont > maxDisdcont) {
          r.discount_percent_automatic = (maxDisdcont * 100) / r.price;
        }
      } else {
        r.nats = Math.max(Math.round(r.price * natsPercent), 0) + msto;
      }
      doc.recalcRowService(r);
    });
    grid.refresh();
  };

  const nomCellTemplate = (r) => {
    return (
    <AutocompleteOTK
      value={r.data.data.nom}
      searchField='name'
      searchFieldsPlus={['article']}
      keyField='ref'
      dataSource={dsProjNoms(proj, lab, doc?.ext_json?.doc_type)}
      dataSourceUserOptions={{ selectServices: true }}
      columns={[
        { dataField: 'article', width: '70', caption: 'Артикул' },
        { dataField: 'name', width: '80', caption: 'Назва' },
        { dataField: 'name_full', caption: 'Повна назва' },
      ]}
      onChange={(nom) => {
        if (nom && r.data?.data) {
          r.data.data.nom = nom;
          r.data.data.price = doc.getPrice(nom.ref);
          r.data.data.vat_rate = nom.vat_rate;
          doc.recalcRowService(r.data.data);
          grid.refresh(true);
        }
      }}
    />
  )};
  const handleRowRemoved = useCallback(() => doc.renumberRows(), [doc]);
  const renderPriceHeader = useCallback(
    () => (
      <div style={{ textAlign: 'center' }}>
        Ціна
        <br />
        (прайс)
      </div>
    ),
    []
  );
  return (
    <>
      {percentUpVisible && (
        <NatsPopup
          showDiscont={useAddPrice && proj !== projects.kl} //disable discont fur calibr 09-08-2024
          onHide={setPercentUpVisible}
          changeNats={changeNats}
          defNats={doc.services?.[0].nats / doc.services?.[0].price}
        />
      )}

      <Toolbar className='otk-toolbar table-toolbar dx-theme-border-color'>
        <Item
          location='before'
          locateInMenu='auto'
          widget='dxButton'
          disabled={docReadOnly}
          options={addButtonOptions}
        />

        <Item
          location='before'
          locateInMenu='auto'
          widget='dxButton'
          disabled={docReadOnly}
          options={copyButtonOptions}
        />
        <Item widget='dxButton' location='before' locateInMenu='auto' options={addPercentButtonOptions} />
        <Item widget='dxButton' location='after' locateInMenu='auto' options={columnsButtonOptions} />
      </Toolbar>
      <DataGrid
        ref={servicesDataGridRef}
        keyExpr={'row'}
        noDataText='Список порожній'
        allowHiding={true}
        allowColumnResizing
        allowColumnReordering={true}
        columnAutoWidth
        columnChooser={columnChooserOptions}
        columnResizingMode='widget'
        dataSource={dataSource}
        errorRowEnabled={false}
        focusedRowEnabled={!docReadOnly}
        hoverStateEnabled
        remoteOperations={false}
        rowAlternationEnabled
        selectTextOnEditStart
        showBorders
        showColumnLines
        showRowLines={false}
        onEditingStart={(e) => { selRowData.current = e.data }}
        onEditorPrepared={selectOnEdit}
        onRowUpdating={handleRowUpdating}
        onRowRemoved={handleRowRemoved}
        width={'100%'}
      >
        <StateStoring enabled={true} type='localStorage' storageKey='storageOrderGrid' ignoreColumnOptionNames={[]} />
        <Editing
          mode='cell'
          allowUpdating={!docReadOnly}
          allowDeleting={!docReadOnly}
          useIcons={true}
          confirmDelete={true}
        >
          <Texts confirmDeleteMessage='Вилучити?' deleteRow='вилучити' />
        </Editing>
        <Paging enabled={false} />
        <Sorting mode={false} />
        <LoadPanel enabled={false} />
        <Column name='article' caption='Артикул' dataField='nom.article' width={70} />
        <Column
          dataField='nom.ref'
          caption='Номенклатура'
          calculateDisplayValue={(row) => row.nom.name}
          editCellComponent={nomCellTemplate}
          placeholder='...вкажіть послугу..'
          minWidth={100}
          width={120}
        />
        <Column
          dataField='price'
          caption='Ціна'
          allowEditing={false}
          headerCellRender={renderPriceHeader}
          minWidth={70}
          width={70}
        />
        <Column
          dataField='quantity'
          dataType='number'
          caption='К-сть'
          minWidth={60}
          width={60}
          editorOptions={{ min: 1 }}
          allowEditing={!docReadOnly}
        />
        <Column dataField='spec' caption='Спецціна' allowEditing={false} width={80}>
          <Format type='fixedPoint' precision={2} />
        </Column>
        <Column
          dataField='discount_percent_automatic'
          caption='%скидки'
          allowEditing={false}
          format={'#0.#'}
          width={80}
        />
        <Column
          dataField='nats'
          caption='Націнка'
          dataType='number'
          allowEditing={!docReadOnly && !isBadAct}
          width={80}
        >
          <Format type='fixedPoint' precision={0} />
          <CustomRule
            message='Недопустимий розмір знижки'
            validationCallback={({ data, value }) => {
              const mstoVal = data.msto ? mstoValue : 0;
              const price = data.spec || data.price;
              const minPrice = Math.min(price, doc.getBasePrice(data.nom.ref) || price);
              return minPrice <= price + (value - mstoVal);
            }}
          />
        </Column>
        <Column
          name='addPerc'
          width={70}
          caption='% прайс'
          headerCellRender={percTemplate}
          calculateCellValue={percentColumn}
        >
          <Format type='fixedPoint' precision={1} />
        </Column>
        <Column
          dataField='msto'
          dataType={'boolean'}
          caption='МСТО'
          allowEditing={!docReadOnly && !isBadAct}
          width={60}
        />
        <Column dataField='amount' caption='Сума з ПДВ' allowEditing={false} width={170}>
          <Format type='fixedPoint' precision={2} />
        </Column>
        <Column dataField='gos_code' caption='Держ.номер' allowEditing={!docReadOnly} width={100} minWidth={90} />
        <Column dataField='vin_code' caption='VIN код' allowEditing={!docReadOnly} width={150} minWidth={120} />

        { !doc.isNew && <Column name='ep' cellComponent={epSimpleBtnTemplate} allowEditing={false} caption='+EП' />}

        <Summary>
          <TotalItem
            column='amount'
            summaryType='sum'
            displayFormat='Разом: {0}'
            valueFormat={(value) => value.toFixed(2)}
          />
          <TotalItem
            column='quantity'
            summaryType='sum'
            displayFormat='{0}'
            valueFormat={(value) => value.toFixed(2)}
          />
        </Summary>
      </DataGrid>
    </>
  );
};
