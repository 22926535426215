import { useState, useMemo } from 'react';
import { SearchDropdown} from 'components/search-dropdown';
import { Partner } from '../partner/partner';
import { IPatrnerFromState, PartnerDatagrid } from './PartnerGrid';
import { IPartner } from 'types/datatypes/IPartner';

interface PartnerSearchProps {
  partner: IPartner;
  onPartnerClose?:()=>void;
  onSelect:(value:IPartner)=>void;
}

export const PartnerSearch = (props:PartnerSearchProps) => {
  const [ partnerFormState, setPartnerFormState ] = useState<IPatrnerFromState>({
    visible: false,
    new: false,
    ref: props?.partner?.ref ?? '',
  });

  const handlePartnerClose = () => {
    setPartnerFormState((prev) => ({...prev, visible: false}));
    props.onPartnerClose?.();
  };


  const handleEdit = (value:IPatrnerFromState) => {
    setPartnerFormState({ visible: true, new: false, ref: value.ref });
  };

  const handleSelect = (value:IPartner) => {
    props.onSelect(value);
    setPartnerFormState((prev) => ({ ...prev, ref: value?.ref }));
  };

  const handleSave = (partner:IPartner) => {
    if (partnerFormState.new) {
      handleSelect(partner);
    } else {
      setPartnerFormState((prev) => ({ ...prev, visible: false }));
    }
  };

  const popup = useMemo(() => (
    <Partner
      visible={partnerFormState.visible}
      newPartner={partnerFormState.new}
      partnerRef={partnerFormState.ref}
      onSave={handleSave}
      onClose={handlePartnerClose}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [ partnerFormState ]);

  return (
    <>
      <SearchDropdown
        {...props}
        value={props.partner}
        text={props.partner?.name || ''}
        onSelect={handleSelect}
        onEdit={handleEdit}
        minSearchLength={3}
        searchTimeout={500}
        placeholder='контрагент...'
        saveSearchValueOnLeave={true}
        width='100%'
        popupMode='popup'
        dropdownHeight={500}
      >
        <PartnerDatagrid 
          searchField='name'
          setPartnerFormState={setPartnerFormState}
          value={props.partner}
          onSelectionChanged={()=>{}}
        />
      </SearchDropdown>
      {partnerFormState.visible && popup}
    </>
  );
};

