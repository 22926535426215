import { Popup } from "devextreme-react";
import { RefObject } from "react";

import styles from '../labreport.module.scss';

interface PopupLegendProps {
  payDetailPopupRef:RefObject<Popup>,totals: {[x:string]:number};
}

export const PopupLegend = ({payDetailPopupRef, totals}:PopupLegendProps)=>{

 return <Popup ref={payDetailPopupRef} closeOnOutsideClick width={'250'} height={'auto'} showTitle={false}>
 <div>
   <div style={{ paddingBottom: '20px' }}>
     <span className={styles.h4}>Поточний період</span>
     {totals?.range &&
       Object.entries(totals.range).map((el) => {
         const [key, value] = el;
         return (
           <div className={styles.cash} key={`${key}`}>
             <span>{key}</span><span>{value} </span>
           </div>
         );
       })}
   </div>
 
   {totals?.prev && Object.keys(totals.prev).length && (
     <>
       <span className={styles.h4}>Виправлення попередніх</span>
       {Object.entries(totals.prev).map((el) => {
         const [key, value] = el;
         return (
           <div className={styles.cash} key={`${key}`}>
             <span> {key} </span> <span>{value} </span>
           </div>
         );
       })}
     </>
   )}
 </div>
 </Popup>
}
