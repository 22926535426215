import { MenuItem } from "types/otkTypes"

import styles from './PngItem.module.scss'

interface PngItemProps {
    item: MenuItem,
    pngWidth?: string;
    pngHeight?: string;
}
export const PngItem = ({
    item,
    pngWidth = '20',
    pngHeight = '20'
}:PngItemProps) => {
    const hasIcon = !!item.icon;
    return(
    <div className={styles.wrapper}>
    {hasIcon && <img width={pngWidth} height={pngHeight} src={item.icon} alt='' 
      className={styles.png}
    />}
    <span
      style={{paddingLeft:`${hasIcon?pngWidth:0}px`}}
    >{item.text}</span>
    </div>
    )
}