import { IInsurancePolicy } from "types/datatypes/IInsurance";

interface CashlessPaymentFormProps{
    data:  IInsurancePolicy;
}

export const CashlessPaymentForm = ({data}:CashlessPaymentFormProps) => {
    
    return (
        <div>
            <h4>Банківські реквізити</h4>
        </div>
    );
};
