import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateBox, Form, LoadPanel, NumberBox, TextArea, TextBox } from 'devextreme-react';
import Validator, { RequiredRule, CustomRule } from 'devextreme-react/validator';
import { ColCountByScreen, EmptyItem, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { diiaStatuses, DX_DATE_DISPLAY_FORMAT, FORM_STYLING_MODE } from 'app-constants';
import { DocInfoBar, DocMenu, NomsSearch, OrdersSearch, StickyBar } from 'components';
import { docValidationMsg, joinBackValidationMsgs, messages } from 'messages';
import {
  mapDiiaStatusesToTypes,
  NOMS_VALIDATION_ERROR_MSG,
  NO_ERRORS_DESCRIPTION_TEXT,
  PRICES_VALIDATION_ERROR_MSG,
} from './constants';
import { gqlClient } from 'gql-client';
import { loader } from 'graphql.macro';
import { usePrices, useDocState, usePayKinds } from 'hooks';
import styles from './lab-report.module.scss';
import { ReportsSearch } from './reports-search';
import { FORM_STYLING_MODE_UNDER } from './../../app-constants';
import { uniqBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuth } from 'contexts';
import { DetailComponent } from './DetailComponent';

const getLabReport = loader('./getLabReport.graphql');
const getOrderServices = loader('./getOrderServices.graphql');
const updateLabReport = loader('./updateLabReport.graphql');
const defaultPayKind = '8cb8e96e-63da-11ec-bb44-00155d000c06'; //cash less

export const LabReport = () => {
  const [ nomsDataSource, setNomsDataSource ] = useState(null);
  const { lab, options } = useAuth();
  const { payKindByRef } = usePayKinds();
  const [ data, setData, { loading, preSave } ] = useDocState({
    defaultData: {
      spot_cashless: false,
    },
    load: (id) => gqlClient.query({
      query: getLabReport,
      variables: { ref: id },
    })
      .then(async (response) => {
        const report = response?.data?.lab_report?.[0];
        if (!report.ref) throw new Error();
        report.lab = response.data?.branch.jsb.suffix;
        return report;
      }),
    update: (state, data) => ({ ...state, ...data }),
  });

  const { loadPrices, getPrice, findPrice } = usePrices(null, () => {});

  const validationGroupRef = useRef();
  const detailPopupRef = useRef();
  const navigate = useNavigate();
  const hasOrder = true;

  const handleFieldChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.element.id]: e.value,
    }));
  };

  // create a new datasource which contains noms from chosen order
  // const updatePossibleServices = () => {
  //   if (!data?.invoice?.ref) return;
  //   gqlClient.query({
  //     query: getOrderServices,
  //     variables: { ref: data.invoice.ref },
  //   })
  //     .then(async (response) => {
  //       const report = response?.data?.buyers_orders?.[0];
  //       if (!report.ref) throw new Error();
  //       const noms = report?.services ?
  //         uniqBy(report.services.map((sRow) => ({ ...sRow.nom })), 'ref') : [];
  //       setNomsDataSource(noms);
  //       return noms;
  //     });
  // };

  // set datasource on services change
  useEffect(() => {
    // updatePossibleServices();
    if (!data?.invoice?.ref) return;
    gqlClient.query({
      query: getOrderServices,
      variables: { ref: data.invoice.ref },
    })
      .then(async (response) => {
        const report = response?.data?.buyers_orders?.[0];
        if (!report.ref) throw new Error();
        const noms = report?.services ? uniqBy(report.services.map((sRow) => ({ ...sRow.nom })), 'ref') : [];
        setNomsDataSource(noms);
        return noms;
      });
  }, [ data?.invoice ]);

  const handleOrderSelect = (e) => {
    
    const newRp = (e.pay_kind === options.easyPay.partUUID) ? data.rp : 0;

    setData((prev) => ({
      ...prev,
      invoice: {
        ref: e.ref || '',
        pay_kind: e.pay_kind || defaultPayKind,
        caption: e.caption || '',
        date: e.date || '',
      },
      spot_cashless: e.pay_kind === defaultPayKind,
      partner: e.partner || prev.partner,
      rp: newRp,
      source_report: null,
    }));

    // load prices on order change then update amount by price of chosen service
    loadPrices(data?.invoice?.date).then((newPrices) => {
      setData((prev) => ({ ...prev, amount: findPrice(newPrices, prev?.service?.ref)}));
    });
  };

  const handleNomSelect = (e) => {
    setData((prev) => ({
      ...prev,
      service: {
        ref: e.ref || '',
        name: e.name || '',
      },
      // change amount only if there is a price for chosen nom
      amount: getPrice(e.ref) || prev.amount,
    }));
  };

  const handleSourceReportSelect = (e) => {
    setData((prev) => ({
      ...prev,
      source_report: {
        ref: e.ref || '',
        caption: e.caption || '',
      },
      invoice: e.invoice,
      spot_cashless: e.invoice.pay_kind === defaultPayKind,
    }));
  };

  const validatePrices = () => data.amount >= data.rv;// + data.rp;
  const validateService = () => (nomsDataSource?.find((nom) => nom.ref === data?.service.ref));
  const validateForm = () => {
    let errorMessage = '';
    const result = validationGroupRef.current.instance.validate();

    if (!validatePrices()) 
       errorMessage += docValidationMsg('Сума повинна бути більше або дорівнює ніж РП + РВ');
    

    if (hasOrder && !validateService()) 
      errorMessage += docValidationMsg(NOMS_VALIDATION_ERROR_MSG);

    return errorMessage || (!result.isValid && docValidationMsg(messages.HAS_INVALID_FIELDS));
  };

  const handleFormSave = async () => {
    const err = validateForm();
    if (err) return Promise.reject(err);
    
    const doctosave = {
      _id: data._id,
      ref: data.ref,
      status: data.status,
      partner: data.partner.ref,
      invoice: data.invoice.ref,
      service: data.service.ref,
      next_date: data.next_date,
      amount: data.amount,
      source_report: data.source_report?.ref,
      rv: data.rv,
      rp: data.rp,
      note: data.note,
    };

    preSave();

    const response = await gqlClient.mutate({
      mutation: updateLabReport,
      variables: { input: doctosave },
    });

    if (response?.errors) return Promise.reject(joinBackValidationMsgs(response.errors));
    return Promise.resolve(data.ref);

  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rightItems = useMemo(()=>([
    {
      id:'cancel',
      text:'Анулювати',
      onClick: () => {
        setData((prev) => ({...prev, status: diiaStatuses.CANCELED.status }));
      },
      disabled: diiaStatuses.CANCELED.status === data.status,
    },
    {
      id:'invalidate',
      text:'Недійсний',
      onClick:()=> setData((prev) => ({...prev, status: diiaStatuses.INVALID.status})),
      disabled: diiaStatuses.INVALID.status === data.status,
    },
  ]))

  return (
    <div>
      <StickyBar>
        <DocInfoBar
          name='Протокол ОТК'
          data={{
            date: data.date,
            number: data.number_doc,
          }}
          loading={loading}
        >
          {data.has_error &&
            <div className={'otk-tag otk-status-error'}>
              <div className='dx-icon-warning'></div>&nbsp;Документ з помилками
            </div>
          }
          {data.vehicle_map ?
            <div className={'otk-tag otk-status-success'}>
              <div className='dx-icon-check'></div>&nbsp;Відповідність ТО
            </div> :
            <div className={'otk-tag otk-status-error'}>
              <div className='dx-icon-clear'></div>&nbsp;Невідповідність ТО
            </div>
          }
          {data.dangerous && <div className={'otk-tag otk-status-default'}>Небезпечний</div>}
          {data.has_error && <div className={'otk-tag otk-status-default'}>Міжнародний ТО</div>}

          <div className={styles.diiaStatus}>
            &nbsp;&nbsp;Статус Дія:&nbsp;
            <div className={`otk-tag otk-status-${mapDiiaStatusesToTypes[data.status] || 'default'}`}>
              {Object.values(diiaStatuses).find((value) => value.status === data.status)?.statusText }
            </div>
          </div>
        </DocInfoBar>
        <DocMenu isDocNew={false} onSave={handleFormSave} rightItems={rightItems}/>
      </StickyBar>
      <LoadPanel visible={loading}/>

      <div className='content-block otk-content-block'>
        <div className={`otk-doc-container ${styles.docContainer} ${data.has_error ? '' : styles.docContainerNoErrors} dx-card`}>
          <div className={styles.form}>
            <ValidationGroup ref={validationGroupRef}>
              <Form labelLocation='left' formData={data}>
                <GroupItem>
                  <ColCountByScreen xs={2} sm={4} md={4} lg={4} />
                  <SimpleItem colSpan={2} >
                    <Label text='Контрагент' />
                    <TextBox value={data?.partner?.name} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Номер звіту' />
                    <TextBox value={data.number_doc} stylingMode={FORM_STYLING_MODE_UNDER} readOnly
                      inputAttr={{ class: 'rightaligntext' }}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Лабораторія' />
                    <TextBox value={data.lab} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    <Label text='Послуга' />
                    <NomsSearch
                      dataSource={nomsDataSource}
                      nom={data?.service}
                      onSelect={handleNomSelect}
                      readOnly={false}
                      stylingMode={FORM_STYLING_MODE}
                      validator={
                        <Validator>
                          {hasOrder && nomsDataSource &&
                            < CustomRule validationCallback={validateService} message={NOMS_VALIDATION_ERROR_MSG}/>
                          }
                        </Validator>
                      }
                    />
                  </SimpleItem>
                  <GroupItem colSpan={2} colCount={3}>
                    <SimpleItem colSpan={2}>
                      <Label text='Статус Дія' />
                      <TextBox
                        readOnly
                        value={Object.values(diiaStatuses).find(
                          (value) => value.status === data.status)?.statusText}
                        stylingMode={FORM_STYLING_MODE_UNDER}
                      />
                    </SimpleItem>
                    {/* { diiaStatuses.CANCELED.status !== data.status &&
                     <SimpleItem>
                       <Button
                         onClick={() => {
                           setData((prev) => ({
                             ...prev,
                             status: diiaStatuses.CANCELED.status,
                           }));
                         }} >Анулювати
                       </Button>
                     </SimpleItem>}
                     { diiaStatuses.CANCELED.status !== data.status &&
                     <SimpleItem>
                       <Button
                         onClick={() => {
                           setData((prev) => ({
                             ...prev,
                             vehicle_map: true,
                           }));
                         }} >Невідповідність
                       </Button>
                     </SimpleItem>} */}
                  </GroupItem>
                  <SimpleItem colSpan={2}>
                    <Label text='Модель ТС' />
                    <TextBox
                      readOnly
                      value={data.vehicle_model?.name}
                      stylingMode={FORM_STYLING_MODE_UNDER}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Дата звіту' />
                    <DateBox
                      readOnly
                      displayFormat={DX_DATE_DISPLAY_FORMAT}
                      value={data.date}
                      stylingMode={FORM_STYLING_MODE_UNDER}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <Label text='Дата наст. ОТК' />
                    <DateBox
                      readOnly
                      value={data.next_date}
                      displayFormat={DX_DATE_DISPLAY_FORMAT}
                      stylingMode={FORM_STYLING_MODE_UNDER}
                      onValueChanged={(e) => {
                        setData((prev) => ({...prev, next_date: e.value}));
                      }}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    <Label text='Держ. номер' />
                    <TextBox value={data.gnumber} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <Label text='VIN' />
                    <TextBox value={data.vin} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <Label text='Бланк' />
                    <TextBox value={`${data.blank_series || ''} ${data.blank_number || ''}`} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>

                  <SimpleItem colSpan={2}>
                    <Label text='Тип оплати' />
                    <TextBox value={payKindByRef(data.invoice?.pay_kind)?.name || ''} stylingMode={FORM_STYLING_MODE_UNDER} readOnly/>
                  </SimpleItem>

                  <EmptyItem colSpan={2}/>
                  <GroupItem colSpan={2} colCount={5}>
                    <SimpleItem colSpan={3}>
                      <Label text='Сума' />
                      <NumberBox
                        id='amount'
                        inputAttr={{ class: 'rightaligntext' }}
                        value={data.amount}
                        stylingMode={FORM_STYLING_MODE}
                        onValueChanged={handleFieldChange}
                        min={0}
                        // readOnly={diiaStatuses.CANCELED.status === data.status}
                      >
                        <Validator>
                          <RequiredRule />
                          <CustomRule validationCallback={validatePrices} message={PRICES_VALIDATION_ERROR_MSG}/>
                        </Validator>
                      </NumberBox>
                    </SimpleItem>
                    <SimpleItem colSpan={2} visible={lab['РВ']}>
                      <Label text='РВ' />
                      <NumberBox
                        id='rv'
                        inputAttr={{ class: 'rightaligntext' }}
                        value={data.rv}
                        stylingMode={FORM_STYLING_MODE}
                        onValueChanged={handleFieldChange}
                        min={0}
                        readOnly={diiaStatuses.CANCELED.status === data.status}
                      >
                        <Validator>
                          <CustomRule validationCallback={validatePrices} message={PRICES_VALIDATION_ERROR_MSG}/>
                        </Validator>
                      </NumberBox>
                    </SimpleItem>
                  </GroupItem>
                </GroupItem>

                <GroupItem caption='  '>
                </GroupItem>
                <GroupItem>
                  <ColCountByScreen xs={1} sm={2} md={4} lg={4} />
                  <SimpleItem visible={hasOrder} colSpan={4}>
                    <Label text='Замовлення' />
                    <OrdersSearch
                      filterData={{
                        partnerRef: data?.partner?.ref,
                        dateLimit: dayjs(data?.date).add(-6, 'month').format(),
                      }}
                      order={data?.invoice}
                      onSelect={handleOrderSelect}
                      readOnly={false}
                      stylingMode={FORM_STYLING_MODE}
                      onDetailButton={() => detailPopupRef.current.instance.show()}
                      detailComponent={
                        <DetailComponent
                          detailPopupRef={detailPopupRef}
                          order={data?.invoice}
                          onSelect={(rowData) => setData((prev) => ({...prev, amount: rowData.price}))}
                        />
                      }
                      onOpenDocButton={data?.invoice ? () => navigate(`/order/${data.invoice.ref}`) : undefined }
                    />
                  </SimpleItem>
                </GroupItem>

                <GroupItem>
                  <ColCountByScreen xs={1} sm={2} md={4} lg={4} />

                  <SimpleItem colSpan={4}>
                    <Label text='Виправлення докум.' />
                    <ReportsSearch
                      report={data.source_report}
                      onSelect={handleSourceReportSelect}
                      stylingMode={FORM_STYLING_MODE}
                      filterData={{
                        thisDoc: data?.ref,
                        invoice: data?.invoice,
                        partnerRef: data?.partner?.ref,
                      }}
                    />
                  </SimpleItem>
                </GroupItem>

                <SimpleItem>
                  <Label text='Коментар' />
                  <TextArea
                    id='note'
                    value={data.note}
                    stylingMode={FORM_STYLING_MODE}
                    onValueChanged={handleFieldChange}
                  />
                </SimpleItem>
              </Form>
            </ValidationGroup>
          </div>

          {data.has_error &&
            <div className={styles.errorsWrapper}>
              <div className={`dx-theme-border-color ${styles.errorsContainer}`}>
                <div className={`dx-icon-warning ${styles.errorsIcon}`} />
                <p className={`${styles.errorMsgs} ${data.error ? styles.errorMsgsNoDesc : ''}` }>
                  {data.error || NO_ERRORS_DESCRIPTION_TEXT}
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};


