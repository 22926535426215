import { gql } from '@apollo/client';
import { gqlClient } from 'gql-client';
import CustomStore from 'devextreme/data/custom_store';
import { ILab } from 'types/datatypes/ILab';
import { LoadOptions } from 'devextreme/data';
import { ApolloAnswer } from 'types/otkTypes';

const gQuery = gql(
  'query getLab($jfilt: [JSON]) { getLab(all:true, jfilt: $jfilt)}'
);

interface LabsCustomStore extends CustomStore<ILab,any> {
  labs?:Array<ILab>;
}


let labs:Array<ILab> = [];

const loadLabs = async (options:LoadOptions<ILab> = {}):Promise<ApolloAnswer<ILab>> => {
  if (!labs.length) {
    const resp = await gqlClient.query({ query: gQuery });
    labs = resp?.data?.getLab || [];
  }
  const { searchExpr, searchValue } = options;
  if (searchValue) {
    return {
      data: labs.filter((r) => r[searchExpr as string].includes(searchValue)),
    };
  }
  return { data: labs };
};

const getByKey = async (key:string):Promise<any>=> {
  if (!labs.length) await loadLabs();
  return labs.find((r) => r.ref === key);
};

export const dsLabs:LabsCustomStore = new CustomStore({
  key: 'ref',
  byKey: getByKey,
  load: loadLabs,
});

dsLabs.labs = labs;
