import { ApolloProvider } from '@apollo/client';
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { gqlClient } from './gql-client.js';
import './polyfills';

Sentry.init({
  dsn: 'https://ca08fab3b9904cbca1e2fecd76225780@o379920.ingest.sentry.io/4504474958626816',
  // integrations: [ new BrowserTracing() ],
  // tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <App />
    </ApolloProvider>
  // </React.StrictMode>
);


