import dayjs, { Dayjs } from 'dayjs';
import { gqlClient } from 'gql-client';
import { gql } from 'graphql.macro';
import { IOptions } from 'types/IOptions';

export const splitId = (id:string) => {
  const [ className, ref ] = id?.split('|') ?? [];
  return { className, ref };
};

export const shallowEqual = (object1:any, object2:any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const updateBranchOptions = async(branchOptions:any) => gqlClient.mutate({
    mutation: gql`
      mutation updateBranchOptions($input: JSONObject) {
        updateBranchOptions(input: $input)
      }
    `,
    variables: { input: branchOptions },
  });

export const createMultiTagHandler = (maxDisplayedTags:any) => (
  (args:any) => {
    const selectedItemsLength = args.selectedItems.length;

    if (selectedItemsLength < maxDisplayedTags) {
      args.cancel = true;
    } else {
      args.text = `Усе (${selectedItemsLength})`;
    }
  }
);

export const selectOnEdit = (args:any) => {
  const input = args.element.querySelector('.dx-texteditor-input');
  if (input !== null) input.select();
};

export const getMstoValue = (date:Dayjs, options:IOptions) => {
  if (!date) date = dayjs().startOf('day');
  let mstoValue = options?.buyers_order?.mstoValue ?? 250;
  if (date < dayjs('20230801')) mstoValue = 240;
  return mstoValue;
};


