import { FormField } from 'components';
import { useRef } from 'react';
import styles from '../../styles/style.module.scss';
import { Button, TextBox } from 'devextreme-react';
import { API_HOST } from 'app-constants';

export const ByLinkPaymentForm = ({ data }) => {
  const dataRef = useRef({
    phoneSMS: data?.phone ?? '',
    phoneViber: data?.phone ?? '',
    email: data?.email ?? '',
  });

  const sendSMS = () => {
    fetch(`${API_HOST}/sendSMS`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ message: data.policy_payment.forwardUrl, recipients: [dataRef.current.phoneSMS] }),
      headers: { 'Content-Type': 'application/json' },
    });
  };

  const sendAssurancePayMail = () => {
    fetch(`${API_HOST}/sendAssurancePayMail`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ ref: data.ref, mail: dataRef.current.email }),
      headers: { 'Content-Type': 'application/json' },
    });
  };

  return (
    <FormField textWidth={'220px'} text={'Надіслати: '} fluid={true}>
      <div style={{ width: '100%' }}>
        <FormField textWidth={'150px'} text={'СМС:'}>
          <div className={styles.formElementDiv}>
            <TextBox
              defaultValue={data?.phone}
              stylingMode='outlined'
              width='200px'
              // mask={'38\\0(00)000-0000'}
              // maskChar={'.'}
              // useMaskedValue={true}
              onValueChanged={(props) => {
                dataRef.current.phoneSMS = props.value;
              }}
            />
            <Button text='Надіслати' onClick={sendSMS} />
          </div>
        </FormField>
        <FormField textWidth={'150px'} text={'Viber:'}>
          <div className={styles.formElementDiv}>
            <TextBox
              value={data?.phone}
              stylingMode='outlined'
              width='200px'
              // readOnly={true}
            />
            <Button disabled text='Надіслати' />
          </div>
        </FormField>
        <FormField textWidth={'150px'} text={'Email:'}>
          <div className={styles.formElementDiv}>
            <TextBox
              value={data?.email}
              stylingMode='outlined'
              width='200px'
              onValueChanged={({ value }) => { dataRef.current.email = value; }}
            />
            <Button text='Надіслати' onClick={sendAssurancePayMail} />
          </div>
        </FormField>
      </div>
    </FormField>
  );
};

// TerminalPaymentForm.propTypes = {
//   data: PropTypes.object,
// };

export default ByLinkPaymentForm;
