import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DoclistPageTemplate } from 'components';
import { Column, Lookup } from 'devextreme-react/data-grid';
import { loader } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
import { OptionsBar } from './components/optionsBar/optionsBar';
import { useNavigate } from 'react-router-dom';
import { dsProj } from 'datasources';

const pageName = 'Термінальні документи';

const dsGetOrders = loader('./gql/list.graphql');
const dsOrders = createDataSource(dsGetOrders);
dsOrders.on('loading', (options) => {
  options.userData = dsOrders?.userData;
});

export const TerminalListDocsPage = () => {
  const [ userData, setUserData ] = useState();
  const navigate = useNavigate();
  const gridRef = useRef();

  useEffect(() => {
    dsOrders.userData = userData;
    gridRef.current.instance.refresh();
  }, [ userData ]);

  const handleOptionsChanghe = useCallback(({ phone, doctype }) => setUserData({ phone, doctype }), [])

    return (
      <>
        <DoclistPageTemplate
        gridRef={gridRef}
        deps={[ userData, dsProj ]}
        pageName={pageName}
        // showFilter={false}
        remoteFiltering={false}
        sorting={false}
        dataSource={dsOrders}
        columnsDataFields={{
          date: 'date',
          docNumber: 'number_doc',
        }}
        onEdit={(ref) => navigate(`/order/${ref}`)}
        stateStoringEnabled={true}
        optionsComponent={(
        <OptionsBar onChange={handleOptionsChanghe} />)}
      >
        <Column
          dataField='proj'
          dataType='string'
          caption='Тип'
          alignment='left'
          allowHiding={false}
          allowResizing={true}
          width={60}
        >
          <Lookup
            dataSource={dsProj}
            valueExpr='ref'
            displayExpr='name'
        />
        </Column>
         <Column
          dataField='partner.name'
          dataType='string'
          caption='Контрагент'
          alignment='left'
          allowHiding={false}
          allowResizing={true}
          width={160}
          minWidth={160}
        />
        <Column
          dataField='doc_amount'
          dataType='number'
          caption='Сума'
          alignment='left'
          allowHiding={false}
          allowResizing={true}
          width={60}
          maxWidth={60}
        />
        </DoclistPageTemplate>
        </>
  );
};
