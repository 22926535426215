import { DataGrid } from "devextreme-react";
import { RefObject, useCallback } from "react";
import { IReportRow } from "../IReportRow";
import { Link } from "react-router-dom";

import styles from '../labreport.module.scss';
import { Column, Scrolling, Summary, TotalItem } from "devextreme-react/data-grid";
import dayjs from "dayjs";
import { DATE_DISPLAY_FORMAT_DATEONLY } from "app-constants";
import { labels } from "../helper";

interface DataGridReportProps {
    gridRef: RefObject<DataGrid<any, any>>;
    dataSource: IReportRow[];
    rvVisible: boolean ;
  }; 
  
export const DataGridReport = ({ gridRef, dataSource, rvVisible }:DataGridReportProps) => {
  
    const cellLabRender = useCallback(
      ({ data }: {data: IReportRow}) =>
        data.number_doc ? <Link to={`/lab_report/${data.ref}`}>{data.number_doc}</Link> : <>{data.number_doc}</>
      , []);
  
    const cellInvoiceRender = useCallback(
      (cell:any) => (cell.text ? <Link to={`/order/${cell.data.invoiceRef}`}>{cell.text}</Link> : <>{cell.text}</>)
      , []);
  
    return (
      <DataGrid
        ref={gridRef}
        keyExpr='number_doc'
        className={styles.dataGrid}
        showBorders
        dataSource={dataSource}
        focusedRowEnabled
        paging={{ enabled: false }}
        columnAutoWidth
      >
        <Scrolling mode='virtual'/>
        <Column
          dataField='vehicle_map'
          caption={labels.tableHeads.vehicle_map}
          dataType='boolean'
          alignment='center'
          width={40}
        />
        <Column
          dataField='number_doc'
          caption={labels.tableHeads.number_doc}
          dataType='string'
          alignment='center'
          cellRender={cellLabRender}
          width={120}
        />
        <Column dataField='blank' caption={labels.tableHeads.blank} dataType='string' alignment='center' width={90} />
        <Column dataField='status' caption={labels.tableHeads.status} dataType='string' alignment='center' width={120} />
        <Column
          dataField='date'
          caption={labels.tableHeads.date}
          dataType='date'
          alignment='center'
          calculateCellValue={(rowData:IReportRow) => dayjs(rowData.date).format(DATE_DISPLAY_FORMAT_DATEONLY)}
          width={90}
        />
        <Column dataField='time' caption={labels.tableHeads.time} alignment='center' width={80} />
        <Column
          name='TypeName'
          dataField='vehicle_model.type.name'
          caption={labels.tableHeads.vehicle_model.type.name}
          dataType='string'
          visible={false}
          width={120}
        />
        <Column
          name='BrandName'
          dataField='vehicle_model.brand.name'
          caption={labels.tableHeads.vehicle_model.brand.name}
          dataType='string'
          visible={false}
          width={120}
        />
        <Column
          name='ModelName'
          dataField='vehicle_model.name'
          caption={labels.tableHeads.vehicle_model.name}
          dataType='string'
          visible={false}
          width={120}
        />
        <Column
          dataField='service.name'
          caption={labels.tableHeads.service.name}
          dataType='string'
          // alignment='center'
          width={50}
        />
        <Column dataField='gnumber' caption={labels.tableHeads.gnumber} dataType='string' width={120} />
        <Column
          cssClass={styles.partnerColumn}
          dataField='partner_name'
          caption={labels.tableHeads.partner_name}
          dataType='string'
          alignment='left'
        />
        <Column dataField='individual_legal' caption={labels.tableHeads.individual_legal} dataType='string' width={50} />
        <Column
          dataField='pay_kind'
          caption={labels.tableHeads.pay_kind}
          dataType='string'
          cellRender={cellInvoiceRender}
          width={80}
        />
        <Column
          dataField='invoiceNumberDoc'
          caption={labels.tableHeads.order}
          dataType='string'
          alignment='center'
          cellRender={cellInvoiceRender}
          width={140}
        />
        <Column dataField='amount' caption={labels.tableHeads.amount} dataType='number' alignment='right' />
        <Column dataField='rp' caption={labels.tableHeads.rp} dataType='number' alignment='right' />
        <Column dataField='rv' caption={labels.tableHeads.rv} dataType='number' alignment='right' visible={rvVisible} />
        <Summary>
          <TotalItem
            column='amount'
            summaryType='sum'
            valueFormat={(value:any) => value.toFixed(2)}
            displayFormat='Всього: {0}'
          />
          <TotalItem column='rp' summaryType='sum' valueFormat={(value: number) => value.toFixed(2)} displayFormat='{0}' />
          {rvVisible && (
            <TotalItem column='rv' summaryType='sum' valueFormat={(value: number) => value.toFixed(2)} displayFormat='{0}' />
          )}
        </Summary>
      </DataGrid>
    );
  };
  