import { gql } from 'graphql.macro';
import { createDataSource } from 'utils/gql-datasource';
import { CustomStoreExt } from 'types/datatypes/classes';
import { IPartner } from 'types/datatypes/IPartner';

const dsGetPartners = gql `query dsGetPartners($ref: String, $limit: Int, $jfilt: [JSON],$userData: JSON, $offset: Int) {
  totalcount: partners(ref: $ref, jfilt: $jfilt, totalCount: 1, userData:$userData) {totalcount}
  list: partners(ref: $ref, limit: $limit, jfilt: $jfilt, offset: $offset, userData:$userData) {
    ref name edrpou id parent is_buyer is_supplier legal_address note name_full individual_legal inn isCorporate phones }
}`;



class DsPartners extends CustomStoreExt<IPartner,'ref'> {
  byEdrpou?: (edrpou:string) => Promise<IPartner|undefined>;
  byPhone?: (phone:string) => Promise<IPartner|undefined>;
};

export const dsPartners:DsPartners = createDataSource<IPartner,'ref'>(dsGetPartners);

dsPartners.byEdrpou = async (edrpou) => {
  const options = { filter: [ 'edrpou', '=', edrpou ] };
  return dsPartners.load(options)
   .then((response:any) => response.data?.[0]);
};

dsPartners.on('loading', (options:any) => {
  options.userData = dsPartners.userData;
});

dsPartners.byPhone = async (phone) => {
  const options = { filter: [ 'phones', 'contains', phone ] };
  return dsPartners.load(options)
      .then((response:any) =>response?.data?.[0]);
};
