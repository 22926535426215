import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, Popup } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';

// import PropTypes from 'prop-types';
import { randomStr } from 'utils/random-str';
import { FORM_STYLING_MODE } from 'app-constants';
import CustomStore from 'devextreme/data/custom_store';
import { type Column, RowClickEvent } from 'devextreme/ui/data_grid';


const poupheight = 300;


interface CustomStoreUserOptions {
  userOptions: object
} 

interface OtkStore extends CustomStore<any, any>, CustomStoreUserOptions{
  
}
interface Props {
  columns?: (string|Column)[];
  onChange: (Object:any)=>void;
  dataSource:  OtkStore,
  dataSourceUserOptions?:{
      selectServices?: boolean;
    },
  searchField:string,
  searchFieldsPlus?:[string],
  keyField: string;
  value:  
   {
     ref: string,
     name: string,
     [keyField:string]: string
   }
  showTitle?:boolean|false;
  placeholder?:string;
}

export const AutocompleteOTK = (props:Props) => {
  const dataSource = props.dataSource;
  if (props.dataSourceUserOptions) dataSource.userOptions = props.dataSourceUserOptions;

  const searchField = props.searchField || 'name';
  const searchFieldsPlus = props?.searchFieldsPlus;
  const keyField = props.keyField || 'ref';

  const ref = useRef<DataGrid>(null);
  const textBoxRef = useRef<TextBox>(null);
  const [value, setValue] = useState<string>(props.value[searchField]);
  const [row, setRow] = useState<any[]>([]);
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const [result, setResult] = useState('');
  const [gridVisible, setGridVisible] = useState(false);

  const popupId = useRef(randomStr('id_'));

  const changeValue = ({event}:any) => {
    setGridVisible(true);
    setValue(event?.target.value);
    setRowIndex(0);
    ref?.current?.instance?.clearSelection();
  };

  useEffect(() => {
    props?.value[keyField] && dataSource.byKey(props.value[keyField]).then((val) => setValue(val?.[searchField]));
    setGridVisible(false);
    if (ref.current && props.onChange) {
      const r = ref.current.instance.getSelectedRowsData()[0];
      props.onChange(r);
    }
  }, [dataSource, keyField, props, result, searchField]);

  useEffect(() => {
    if (ref?.current?.instance) {
      let searchFilter:Array<any> = [searchField, 'contains', value];
      if (searchFieldsPlus) {
        searchFilter = [searchFilter];
        searchFieldsPlus.forEach((sf) => {
          searchFilter.push('or');
          searchFilter.push([sf, 'contains', value]);
        });
      }
      ref.current.instance.filter(searchFilter);
    }
  }, [searchField, searchFieldsPlus, value]);

  const rowClick = (e:RowClickEvent) => {
    setRowIndex(e.rowIndex);
    setResult(e.key);
    setRowIndex(e.rowIndex);
    setValue(e.data[searchField]);
    props.onChange(e.data);
  };


  const popupPosition = () => {
    const editPos = textBoxRef.current?.instance.element()?.getBoundingClientRect() ?? {
      bottom: window.innerHeight,
    };
    return window.innerHeight - poupheight > editPos.bottom
      ? { my: 'left top', at: 'left bottom', of: `#${popupId.current}` }
      : { my: 'left bottom', at: 'left top', of: `#${popupId.current}` };
  };

  const scrollToRowIndex = (_rowIndex = rowIndex) => {
    const row = ref.current?.instance.getRowElement(_rowIndex ?? 0);
    row && ref.current?.instance.getScrollable().scrollToElement(row[0]);
  };

  const enterKey = (e:any) => {
    let _rowIndex:number = -1;
    if (!gridVisible) {
      const _rowIndex = ref.current?.instance.getRowIndexByKey(props.value[keyField])?? -1;
      setGridVisible(true);
      setRowIndex(_rowIndex);
      ref.current?.instance.selectRows([props.value[keyField]], false);
      scrollToRowIndex(_rowIndex);
    }
    switch (e.event?.code) {
      case 'ArrowDown': {
        _rowIndex = rowIndex >= 0 ? Math.min(rowIndex + 1, ref?.current?.instance.totalCount()! - 1) : 0;
        break;
      }
      case 'ArrowUp': {
        _rowIndex = rowIndex > 0 ? Math.max(rowIndex - 1, 0) : 0;
        break;
      }
      case 'Enter': {
        if (row) {
          const r = row[0];
          const _rowIndex = ref.current?.instance.getRowIndexByKey(r[keyField]);
          setValue(r[searchField]);
          setResult(r[keyField]);
          setRowIndex(_rowIndex!);
          props.onChange(r);
        } else if (ref.current && ref.current.instance.totalCount() === 1) {
          ref.current.instance.selectRowsByIndexes([0]);
          const r = ref.current.instance.getSelectedRowsData()[0];
          if (r) {
            setValue(r[searchField]);
            setResult(r[keyField]);
            props.onChange(r);
          }
        }
        // e.event.preventDefault();
        break;
      }
      default:
    }

    if (gridVisible && _rowIndex >-1) {
      ref.current?.instance.selectRowsByIndexes([_rowIndex]);
      const rowData = ref.current?.instance.getSelectedRowsData();
      scrollToRowIndex(_rowIndex);
      setRowIndex(_rowIndex);
      setRow(rowData!);
      e.event?.preventDefault();
    }
  };

  return (
    <div>
      <TextBox
        ref={textBoxRef}
        id={popupId.current}
        onInput={changeValue}
        onKeyDown={enterKey}
        value={value}
        placeholder={props.placeholder}
        stylingMode={FORM_STYLING_MODE}
      >
        <TextBoxButton
          name='aaa'
          location='after'
          // type='default'
          options={{
            icon: 'spindown',
            focusStateEnabled: false,
            onClick: () => {
              if (gridVisible) {
                setGridVisible(false);
                textBoxRef.current?.instance.blur();
              } else {
                const _rowIndex = ref.current?.instance.getRowIndexByKey(props.value[keyField]) ?? -1;
                setGridVisible(true);
                setRowIndex(_rowIndex);
                ref.current?.instance.selectRows([props.value[keyField]],false);
                scrollToRowIndex(_rowIndex);
              }
            },
          }}
        />
      </TextBox>

      <Popup
        height={poupheight}
        visible={gridVisible}
        showTitle={props.showTitle || false}
        onShown={() => {
          scrollToRowIndex();
          textBoxRef.current?.instance.focus();
        }}
        shading={false}
        position={popupPosition}
      >
        <div style={{ height: poupheight - 40 }}>
          <DataGrid
            ref={ref}
            key={keyField}
            dataSource={dataSource}
            paging={{ enabled: false }}
            selection={{ mode: 'single' }}
            columns={props?.columns || [searchField]}
            onRowClick={rowClick}
            autoNavigateToFocusedRow={true}
            height={'100%'}
            focusedRowEnabled={true}
            focusedRowKey={rowIndex}
          />
        </div>
      </Popup>
    </div>
  );
};

