import React, { useState, createContext, useContext, useCallback } from 'react';
import { defaultGlobalOptions, DEFAULT_AVATAR_URL } from 'app-constants';
import { signIn as sendSignInRequest, loadOptions, getInit, logout } from 'api/auth';
import { gqlClient, userVar } from 'gql-client.js';
import { gql } from 'graphql.macro';
import { setLoginTimeStamp } from 'hooks/useFirstLoginedView';
import { Projects } from 'datasources/dsProj/ds-proj.js';
import { initapi } from 'pages/insurance-policy/api/services.js';
import { IUser } from 'types/datatypes/IUser';
import { IApiResponse } from 'types/otkTypes';
import { IOptions } from 'types/IOptions';
import { ILab } from 'types/datatypes/ILab';

export interface IAuthContext {
   user:IUser;
   signIn:(email:string, password:string)=>Promise<IApiResponse>;
   signOut:()=>void;
   reAuth:()=>Promise<IApiResponse>,
   loading:boolean,
   options:IOptions,
   lab:ILab,
   projects:Projects,
   getOptions:(branch:any)=>Promise<IOptions>,
   setOptions:(opt:IOptions)=>void;
}

const AuthProvider = (props:any) => {
  const [ user, setUser ] = useState<IUser>();
  const [ loading, setLoading ] = useState(true);

  const [ options, setOptions ] = useState<IOptions>({} as IOptions);
  const [ lab, setLab ] = useState({});
  const [ projects, setProj ] = useState<Projects>();

  const getOptions = useCallback(async (branch:any):Promise<IOptions> => {
    const result = await loadOptions(branch);
    setOptions(renderOptions(options));
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInit = useCallback(async (user:IUser) => {
    const init = await getInit(user);
    const { options, lab, proj } = init??{};
    initapi(options.assurance);
    setOptions(renderOptions(options));
    setLab(lab);
    setProj(new Projects(proj, lab));
  }, []);

  const signIn = useCallback(async (email:string, password:string) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      await loadInit(result.data);
      setUser(result.data);
      userVar(result.data);
    }
    setLoading(false);
    return result;
  }, [loadInit]);

  const reAuth = useCallback(async () => {
    const result:IApiResponse = await gqlClient.query({
      query: gql`query re_auth{re_auth}`,
      errorPolicy: 'none' })
      .then((response) => response.data.re_auth || { isOk: false },
      )
      .then((result) => ({
        isOk: result.isOk ?? true,
        message: result.isOk ? '' : 'Помилка входу',
        data: {
          ...result,
          avatarUrl: result.avatarUrl || DEFAULT_AVATAR_URL,
        },
      }))
      .catch(() => ({
        isOk: false,
        message: 'Помилка входу',
        data:undefined
      }));

    if (result.isOk) {
      setUser(result.data);
      userVar(result.data);
      await loadInit(result.data);
      setLoginTimeStamp();
    }
    setLoading(false);
    return result;
  }, [loadInit]);

  const signOut = useCallback(async () => {
    await logout();
    gqlClient.stop();
    await gqlClient.resetStore();
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
    value={{ user, signIn, signOut, reAuth,
      loading, options, lab, projects, getOptions, setOptions }}
    {...props}
    />
  );
};

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

const renderOptions = (options:IOptions):IOptions => {
  const general = {...defaultGlobalOptions.general, ...options.general};
  const assurance = {...defaultGlobalOptions.assurance, ...options.assurance};
  const easyPay = {...defaultGlobalOptions.easyPay, ...options.easyPay};
  const buyers_order = {...defaultGlobalOptions.buyers_order, ...options.buyers_order};
  const labReports = {...defaultGlobalOptions.labReports, ...options.labReports};
  const closeByDocs = {...defaultGlobalOptions.closeByDocs, ...options.closeByDocs};
  const branchOptions = {...defaultGlobalOptions.branchOptions, ...options.branchOptions};

  return { 
    general,
    assurance,
    easyPay,
    buyers_order,
    labReports,
    closeByDocs,
    branchOptions
   }
}