import { DateRange, PageContent } from 'components';
import {
  ButtonItem,
  Form,
  GroupItem,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form';
import {
  DATE_FORMAT,
  DX_DATE_DISPLAY_FORMAT,
  FORM_STYLING_MODE,
  FORM_STYLING_MODE_UNDER,
  orderOptions,
} from 'app-constants';
import dayjs from 'dayjs';
import { useState, useRef, useCallback, useEffect, useMemo} from 'react';
import { Button, DataGrid, Popup, TextBox, Validator } from 'devextreme-react';

import { showError, showSuccess, showWarning } from 'utils/notify';
import { usePayKinds, useDate } from 'hooks';
import { dsLabReportsFull } from 'datasources';
import { useParams, useNavigate } from 'react-router-dom';
import { gqlClient } from 'gql-client';
import { gql } from '@apollo/client';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { LoadPanel } from 'devextreme-react/load-panel';
import * as Sentry from '@sentry/react';
import { useAuth } from 'contexts';
import { updateBranchOptions } from 'utils/helpers';
import { EmailRule } from 'devextreme-react/validator';
import { IReportRow } from './IReportRow';
import dxDataGrid from 'devextreme/ui/data_grid';
import { DataGridReport } from './components/DataGridReport';
import { groupBy, labels } from './helper';
import { PopupLegend } from './components/PopupLegend';

import styles from './labreport.module.scss';
import { NeedHelp } from 'components/needhelp/NeedHelp';
import { CustomStoreExt } from 'types/datatypes/classes';

interface ReportData {
  range: IReportRow[];
  prev: IReportRow[];
}

const notifySuccesEmailText = 'Звіт надіслано.';
const checkInterval = (start:any, end:any) => dayjs(end).diff(start, 'month', true) <= 1.0;

const buildFilterValue = (filters:any) => {
  const start = dayjs(filters.date.start).startOf('day').format(DATE_FORMAT);
  const end = dayjs(filters.date.end).endOf('day').format(DATE_FORMAT);
  return [
    [['date', '>', start], 'and', ['date', '<', end]],
    'or',
    [['date_update', '>', start], 'and', ['date_update', '<', end]],
  ];
};

const mapNoms = new Map();

export const DayLabReport = () => {
  const { start, end } = useParams();
  const navigate = useNavigate();
  const { startOfMonth, startOfDay, endOfDay, displayDateTime } = useDate();
  const [ showDataGrid, setShowDataGrid ] = useState(false);
  const [ loadingVisible, setLoadingVisible ] = useState(false);
  const [ totals, setTotals ] = useState<{[x:string]:number}>({});
  const [ filters, setFilters ] = useState({
    date: {
      start: start ? startOfDay(start) : startOfMonth(start),
      end: endOfDay(end),
    },
  });
  const {
    lab,
    options: { labReports: gOptions, easyPay: easyPayOptions, branchOptions },
  } = useAuth() ;

  const { payKindByRef, availablePayKinds, payKinds } = usePayKinds();
  const [ data, setData ] = useState<ReportData>({prev:[], range:[]});
  const dgRef = useRef<DataGrid>(null);
  const dgPrevRef = useRef<DataGrid>(null);
  const tabIndex = useRef<'range'|'prev'>();
  const payDetailPopupRef = useRef<Popup>(null);
  const validatorRef = useRef<Validator>(null);

  useEffect(() => {
    mapNoms.clear();
    gOptions.nomChangeLabReport.forEach((r) => mapNoms.set(r.inNom.ref, r.outNom));
  }, [ gOptions ]);

  const exportExcelBuffer = useCallback(
    async (dataGridInstance?: dxDataGrid<IReportRow, any>, dataGridPrevInstance?: dxDataGrid<IReportRow, any>) => {
    const { Workbook } = await import('exceljs');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Поточний');
    const worksheetPrev = workbook.addWorksheet('Виправлення');
    let cell = worksheet.getCell(1, 2);
    cell.value = `Звіт лабораторії ${lab.lab_number} за період`;
    cell = worksheet.getCell(2, 2);
    cell.value = ` з ${displayDateTime(start)} по ${displayDateTime(end)}`;
    dataGridInstance?.columnOption('TypeName', 'visible', true);
    dataGridInstance?.columnOption('BrandName', 'visible', true);
    dataGridInstance?.columnOption('ModelName', 'visible', true);
    await exportDataGrid({
      component: dataGridInstance,
      worksheet,
      topLeftCell: { row: 4, column: 1 },
      customizeCell: ({gridCell, excelCell}) => {
        if (gridCell?.rowType !== 'header' && excelCell.col === 1) {
          excelCell.value = excelCell.value ? '\u{2705}' : '';
        }
      },
    })

    cell = worksheetPrev.getCell(1, 2);
    cell.value = `Виправлення минулих періодів`;

    dataGridPrevInstance?.columnOption('TypeName', 'visible', true);
        dataGridPrevInstance?.columnOption('BrandName', 'visible', true);
        dataGridPrevInstance?.columnOption('ModelName', 'visible', true);
        await exportDataGrid({
          component: dataGridPrevInstance,
          worksheet: worksheetPrev,
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({gridCell, excelCell}) => {
            if (gridCell?.rowType !== 'header' && excelCell.col === 1) {
              excelCell.value = excelCell.value ? '\u{2705}' : '';
          }},
        });

        const res = await workbook.xlsx.writeBuffer();
        
        dataGridPrevInstance?.columnOption('TypeName', 'visible', false);
        dataGridPrevInstance?.columnOption('BrandName', 'visible', false);
        dataGridPrevInstance?.columnOption('ModelName', 'visible', false);
        dataGridInstance?.columnOption('TypeName', 'visible', false);
        dataGridInstance?.columnOption('BrandName', 'visible', false);
        dataGridInstance?.columnOption('ModelName', 'visible', false);

        return res
  }, [displayDateTime, end, lab, start]);

  const inDateRange = useCallback((date:string) => {
      const { start, end } = filters.date;
      return date >= start && date <= end;
    }, [filters.date]);

  const { partUUID, partValue, teminalUUID } = easyPayOptions;
  const calcRowData = (data:IReportRow[]) => {
    const _totals:{[x:string]:number} = {'Каса':0};
    data.forEach((r) => {
      r.individual_legal = r.partner?.individual_legal === 'ЮрЛицо' ? 'Юр' : 'Фіз';
      r.time = dayjs(r.date).format('HH:mm:ss');
      r.blank = `${r.blank_series ?? '-'} ${r.blank_number ? String(r.blank_number).padStart(6, '0') : ''}`;
      r.pay_kind = payKindByRef(r?.invoice?.pay_kind)?.name ?? 'Безготівка';
      r.partner_name = r.partner?.name ?? '';
      r.invoiceRef = r.invoice?.ref;
      r.invoiceNumberDoc = r.invoice?.number_doc;
      if (mapNoms.has(r.service?.ref)) r.service = mapNoms.get(r.service.ref);


      // column "rp"
      if (r?.invoice?.pay_kind === partUUID) {
        r.rp = Math.min(partValue, r.amount);
      } else if (r?.invoice?.pay_kind === teminalUUID) {
        r.rp = r.amount;
      }

      //
      if (!r.amount && !r.source_report && r.status !== 'Аннулирован') {
        if (r?.invoice?.pay_kind === partUUID) {
          r.amount = r.invoice.services.find((s) => r.service.ref === s.nomRef)?.amount || 0;
          r.rp = Math.min(partValue, r.amount);
        } else if (r?.invoice?.pay_kind === teminalUUID) {
          r.amount = r.invoice.services.find((s) => r.service.ref === s.nomRef)?.amount || 0;
        }
      }

      if ( r?.invoice?.badact_reason !== null && r?.invoice?.badact_reason !== 'Платний') {
        r.amount = 0;
      }

      if (r?.invoice?.isSubContract && r.amount) {
        r.amount = r?.invoice?.ext_json?.subNomsPrice?.find((row) => row.nomRef === r.service.ref)?.price || r.amount;
        const isMsto = r.invoice.services.find(ir=> ir.nomRef === r.service.ref)?.msto;
        if (isMsto) r.amount+=orderOptions.mstoValue;
        r.partner_name = `${r.partner_name} (${r.invoice.ext_json?.subPartnerName || ''})`;
      }
      if (r.status === 'Аннулирован') r.rp = r.amount ? r.rp : 0;
      if (r.amount && r?.invoice?.pay_kind === partUUID) {
        const cash = r.amount - partValue;
        _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + partValue;
        _totals['Каса'] += cash;
      } else {
        _totals[r.pay_kind] = (_totals[r.pay_kind] || 0) + r.amount;
      }

      if (r.rv && lab['РВ']) _totals['РВ'] = (_totals['РВ'] || 0) + r.rv;
      delete r.partner;
      delete r.invoice;
    });
    return _totals;
  };

  const applyFilters = async () => {
    setShowDataGrid(false);
    setLoadingVisible(true);
  (dsLabReportsFull as CustomStoreExt<IReportRow, 'ref'>)
      .load({
        filter: buildFilterValue(filters),
        sort: [{ selector: 'date', desc: false }],
        take: 9999,
      } )
      .then((resp) => {
        //@ts-ignore
        const data = resp?.data;
        const grouped = groupBy(data, (repRow:IReportRow) => (inDateRange(repRow.date) ? 'range' : 'prev'));
        const _totals:{[x:string]:any} = {};
        const loadData:ReportData = {} as ReportData;
        grouped.forEach((data, key:string) => {
          _totals[key] = { Каса: 0 };
          _totals[key] = calcRowData(data);
          loadData[key as keyof ReportData] = data;
        });
        setTotals(_totals);
        setData(loadData);
        setLoadingVisible(false);
        setShowDataGrid(true);
      });
  };

  useEffect(() => {
    if (start && end && payKinds.length) applyFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, payKinds]);

  const sendReport = useCallback(async () => {
    const validation = validatorRef.current?.instance.validate()?? {};

    const items = dgRef.current?.instance.getDataSource().items();
    const availablePK = availablePayKinds(lab).filter((r: { disabled: any; }) => !r.disabled).map((r: { name: any; }) => r.name);
    const errorsPlus:any = {};
    items?.forEach((row) => {
      if (!availablePK.includes(row.pay_kind || 'Безготівка')) errorsPlus.typeError = true;
      if (row.has_error) errorsPlus.hasError = true;
    });
    if (errorsPlus?.typeError) {
      if (validation && !validation?.brokenRules) validation.brokenRules = [];
      validation.isValid = false;
      validation?.brokenRules?.push({ message: 'Недопустимий тип оплати', type:'required' });
    }
    if (errorsPlus?.hasError) {
      if (!validation.brokenRules) validation.brokenRules = [];
      validation.isValid = false;
      validation.brokenRules.push({ message: 'Є звіти з помилками', type:'required' });
    }

    if (!validation.isValid) {
      const messages = validation.brokenRules?.map((rule) => rule.message).join('\n');
      showError(messages ?? 'Помилка');
      return;
    }
    // return
    const filename = `daylabreport_${lab.lab_number}`;
    const excelObjext = await exportExcelBuffer(dgRef.current?.instance, dgPrevRef.current?.instance);
    updateBranchOptions(branchOptions);
    const subject = `Щоденний звіт лаб ${String(lab?.lab_number).padStart(4, "0")} ${dayjs(end).format('MM/YYYY')}`; 
    console.log(subject);
    gqlClient
      .mutate({
        mutation: gql`mutation sendLabDayReport($input: JSONObject) {sendLabDayReport(input: $input)}`,
        variables: {
          input: {
            filename,
            mailcc: branchOptions.mailcc,
            //@ts-ignore
            buf: excelObjext.toString('base64'),
            subject: subject,
          },
        },
      })
      .then(({ data: { sendLabDayReport } }) => {
        if (sendLabDayReport?._id === 'ok') showSuccess(notifySuccesEmailText);
        else showError('Помилка надсилання повіломлення');
      })
      .catch((err) => {
        Sentry.captureException(err);
        throw new Error('Помилка надсилання повіломлення');
      });
  }, [availablePayKinds, branchOptions, end, exportExcelBuffer, lab]);

  const buttonApplyOptions = useMemo(()=>({
    disabled: !checkInterval(filters.date.start, filters.date.end),
    text: 'Cформувати',
    icon: 'search',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => navigate(`/daylabreport/${filters.date.start}/${filters.date.end}`)
  }), [filters.date.end, filters.date.start, navigate]);

  const buttonLegendOptions = useMemo(()=>({
    text: 'Розшифровка по оплатам',
    type: 'default',
    stylingMode: 'outlined',
    onClick: () => payDetailPopupRef.current?.instance.show(),
  }), []);

  const changeReq = (e: any) => (branchOptions.mailcc = e.event?.target?.value);

  return (
    <PageContent size='large'>
      <LoadPanel visible={loadingVisible} />
      <div className='otk-page-header' style={{paddingBottom:'10px'}}>
          <span>Звіт Протоколи ОТК за період</span>
          <NeedHelp category='DayLabReport' canChangeCatagory={false} text='Потрібна допомога'/>  
      </div>
      <Form>
        <GroupItem colCount={3} >
          <SimpleItem>
            <DateRange
              startValue={filters.date.start}
              endValue={filters.date.end}
              stylingMode={FORM_STYLING_MODE}
              onRangeChanged={(range:any) => {
                if (!checkInterval(range.start, range.end)) showWarning('Період більше одного місяця');
                setFilters((prev) => ({ ...prev, date: range }));
                setData({} as ReportData);
              }}
              displayFormat={DX_DATE_DISPLAY_FORMAT}
              width='100%'
            />
          </SimpleItem>
          <ButtonItem buttonOptions={buttonApplyOptions} verticalAlignment='center' horizontalAlignment='center'/>
          <ButtonItem buttonOptions={buttonLegendOptions}verticalAlignment='center' horizontalAlignment='center'/>

        </GroupItem>
        <GroupItem cssClass={styles.dataGridItem} visible={showDataGrid}>
          <TabbedItem itemType={'tabbed'}>
            <TabPanelOptions
              deferRendering={false}
              onSelectionChanged={(e: any) => {
                tabIndex.current = e.component.option('selectedIndex') === 0 ? 'range' : 'prev';
              }}
            />
            <Tab title='Поточний період'>
              <DataGridReport gridRef={dgRef} dataSource={data.range} rvVisible={lab['РВ']} />
            </Tab>
            <Tab title='Виправлення попереднього'>
              <DataGridReport gridRef={dgPrevRef} dataSource={data.prev} rvVisible={lab['РВ']} />
            </Tab>
          </TabbedItem>
        </GroupItem>
        <GroupItem colCount={1} visible={showDataGrid}>
          <div className={styles.pagefooter}>
            <div className='dx-field'>
              <div className='dx-field-label'>{labels.mailcc}</div>
              <TextBox
                width={250}
                labelMode='static'
                mode='email'
                id='mailcc'
                defaultValue={branchOptions.mailcc}
                hint='копія отримувача звіту'
                onChange={changeReq}
                stylingMode={FORM_STYLING_MODE_UNDER}
              >
                <Validator ref={validatorRef}>
                  <EmailRule message={'Некоректний email!'} />
                </Validator>
              </TextBox>
            </div>

            <Button
              className={styles.exportButton}
              text='Експорт'
              icon='exportxlsx'
              type='default'
              useSubmitBehavior={false}
              onClick={sendReport}
            />
          </div>
        </GroupItem>
      </Form>
      <PopupLegend payDetailPopupRef={payDetailPopupRef} totals={totals}/>    
    </PageContent>
  );
};


